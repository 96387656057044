import React, { useRef, useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./about.css";
import klogo from "../assets/about-k-logo.svg";
function About() {
  const balls = useRef([]);
  const [x, setX] = useState("0%");
  const [y, setY] = useState("0%");
  useEffect(() => {
    function handleMouseMove(event) {
      const newX = (event.clientX * 100) / window.innerWidth + "%";
      const newY = (event.clientY * 100) / window.innerHeight + "%";
      console.log(newX);
      console.log(newY);
      setX(newX);
      setY(newY);
    }
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const [counterOn, setcounterOn] = useState(false);
  return (
    <div className="About" id="About">
      <div className="about-container">
        <h1 className="about-heading" data-aos="fade-up">
          About Us
        </h1>
        <div className="about-content">
          <div data-aos="fade-up">
            {" "}
            <p>
              Welcome to Kreative Peeps! We're a team of skilled and experienced
              web developers dedicated to creating exceptional online
              experiences for businesses and individuals alike.
              <br />
              <br />
              We believe that every website has the potential to be amazing, and
              we're committed to helping our clients achieve that. Whether you
              need a simple brochure site or a complex e-commerce platform, we
              have the skills and expertise to build it for you.
            </p>
          </div>
          <div className="about-k-logo-container" data-aos="fade-up">
            <img className="about-k-logo" src={klogo} alt="Logo" />
          </div>
        </div>
        <div className="about-2">
          <h1 className="about-2-h1" data-aos="fade-up">
            Why <span className="about-2-h1-gradient">Kreative Peeps?</span>
          </h1>
        </div>
        <ScrollTrigger
          onEnter={() => setcounterOn(true)}
          onExit={() => setcounterOn(false)}
        >
          <div className="about-2-box-container">
            <div className="about-2-box" data-aos="fade-up">
              <h3>GROWTH</h3>
              <h1>
                {counterOn && (
                  <CountUp
                    start={0}
                    end={10}
                    duration={2}
                    delay={0}
                    className="count-h1"
                  />
                )}
                X
              </h1>
              <p>10X Growth for your Business</p>
            </div>
            <div className="about-2-box" data-aos="fade-up">
              <h3>COST</h3>
              <h1>
                {counterOn && (
                  <CountUp
                    start={0}
                    end={70}
                    duration={2}
                    delay={0}
                    className="count-h1"
                  />
                )}
                %
              </h1>
              <p>70% less expensive on all services</p>
            </div>
            <div className="about-2-box" data-aos="fade-up">
              <h3>QUALITY</h3>
              <h1>
                {counterOn && (
                  <CountUp
                    start={0}
                    end={100}
                    duration={2}
                    delay={0}
                    className="count-h1"
                  />
                )}
                %
              </h1>
              <p>We Deliver 100% best quality services</p>
            </div>
          </div>
        </ScrollTrigger>
      </div>
    </div>
  );
}

export default About;
