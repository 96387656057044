import React from "react";
import "./floating.css";
import { FloatButton } from "antd";
import { BsWhatsapp } from "react-icons/bs";
function Floatingbtn() {
  return (
    <div>
      <FloatButton
        type="default"
        size="large"
        className="floating-btn"
        icon={<BsWhatsapp className="floating-icon" />}
        href="http://wa.me/+917010573022"
      />
    </div>
  );
}

export default Floatingbtn;
