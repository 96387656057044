import React, { useRef, useEffect, useState } from "react";
import "./Testimony.css";
import { Carousel } from "antd";
import double1 from "../assets/carousel.svg";
import double2 from "../assets/carousel1.svg";
import firstball from "../assets/Frame 57.svg";
function Testimony() {
  return (
    <div className="Testimony" id="Testimony">
      <div className="background-container">
        <div className="Testimony-container" data-aos="fade-up">
          <div className="test-background">
            {" "}
            <img className="test-first-ball" alt="ball" src={firstball} />
            <img className="test-second-ball" alt="ball" src={firstball} />
          </div>
          <img className="mobile-first-ball" alt="ball" src={firstball} />
          <img className="mobile-second-ball" alt="ball" src={firstball} />
          <h1 className="about-2-h1">
            Tried and Trusted by{" "}
            <span className="about-2-h1-gradient"> Peeps</span>
          </h1>
        </div>
        <div className="Testimony-container-1" data-aos="fade-up">
          <img className="double-1" src={double1} />
          <img className="double-2" src={double2} />

          <Carousel autoplay draggable autoplaySpeed={3000}>
          <div className="carousel-container">
              <div className="carousel-text-container">
                <p className="carousel-p">
                  I recently hired Kreative Peeps to create a new website for my
                  Hostel business, and I'm extremely satisfied with the results.
                  The website they created is visually stunning and easy to
                  navigate, which has helped to attract more visitors to my
                  site. I highly recommend Kreative Peeps for anyone in need of
                  high-quality web development services.
                  <br />
                  <br />
                  <br />
                  Franklin Jacob
                  <br />
                  <br />
                  Founder
                  <br />
                  <br />
                  <h3 className="carousel-h3">Gracelin Home for Ladies</h3>
                </p>
              </div>
            </div>
            <div className="carousel-container">
              <div className="carousel-text-container">
                <p className="carousel-p">
                  I recently hired Kreative Peeps to create a new website for my
                  Hostel business, and I'm extremely satisfied with the results.
                  The website they created is visually stunning and easy to
                  navigate, which has helped to attract more visitors to my
                  site. I highly recommend Kreative Peeps for anyone in need of
                  high-quality web development services.
                  <br />
                  <br />
                  <br />
                  Franklin Jacob
                  <br />
                  <br />
                  Founder
                  <br />
                  <br />
                  <h3 className="carousel-h3">Gracelin Home for Ladies</h3>
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Testimony;
