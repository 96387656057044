import React, { useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { message } from "antd";
function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_zjbxg6l",
        "template_nhsxpds",
        form.current,
        "8n14lZ09EEBvRlmTL"
      )
      .then(
        (result) => {
          console.log(result.text);
          messageApi.open({
            type: "success",
            content: "Message send",
            style: {
              marginTop: "20vh",
            },
          });
        },
        (error) => {
          console.log(error.text);
          messageApi.open({
            type: "error",
            content: "Message not send please sent again",
            style: {
              marginTop: "20vh",
            },
          });
        }
      );
  };
  const [messageApi, contextHolder] = message.useMessage();
  return (
    <div className="Contact" id="Contact">
      <div className="contact-heading" data-aos="fade-up">
        <h1>WE LOVE HEARING FROM YOU</h1>
      </div>
      <div className="form-content">
        {contextHolder}
        <form
          className="contact-inputs"
          data-aos="fade-up"
          ref={form}
          onSubmit={sendEmail}
        >
          <input
            type="text"
            placeholder="Name"
            className="text-input"
            name="from_name"
            required="requried"
            data-aos="fade-up"
          ></input>
          <input
            type="email"
            placeholder="E-mail"
            className="text-email"
            name="email_id"
            required="requried"
            data-aos="fade-up"
          ></input>
          <textarea
            placeholder="Enter the message"
            className="input-textarea"
            name="message"
            required="requried"
            data-aos="fade-up"
          ></textarea>
          <div className="submit-btn-container">
            <button className="submit-button" data-aos="fade-up">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
