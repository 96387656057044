import React, { useRef,useState } from "react";
import "./home.css";
import firstball from "../assets/Frame 57.svg";
import Doublearrow from "../assets/Down Arrow.svg";
import { TypeAnimation } from "react-type-animation";
import Plx from "react-plx";
const parallaxData = [
  {
    start: 0,
    end: 500,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];

function Home() {
  const hoverref = useRef();
  function handleMouseMove(e) {
    const position = hoverref.current.getBoundingClientRect();
    const x = e.pageX - position.left - position.width / 2;
    const y = e.pageY - position.top - position.height / 2;
    hoverref.current.style.transform = `translate(${x * 0.2}px,${y * 0.2}px)`;
  }
  function handleMouseOut() {
    hoverref.current.style.transform = "translate(0px,0px)";
  }

  const hoverref1 = useRef();
  function handleMouseMove1(e) {
    const position = hoverref1.current.getBoundingClientRect();
    const x = e.pageX - position.left - position.width / 2;
    const y = e.pageY - position.top - position.height / 2;
    hoverref1.current.style.transform = `translate(${x * 0.2}px,${y * 0.2}px)`;
  }
  function handleMouseOut1() {
    hoverref1.current.style.transform = "translate(0px,0px)";
  }
  return (
    <>
      <div className="Home" id="Home">
        <div className="home-container">
          <div className="home-background">
            <div className="first-background">
              <img
                className="first-ball"
                alt="ball"
                src={firstball}
                ref={hoverref}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
              />
            </div>
            <div className="second-background">
              <img
                className="second-ball"
                alt="ball"
                src={firstball}
                ref={hoverref1}
                onMouseMove={handleMouseMove1}
                onMouseOut={handleMouseOut1}
              />
            </div>
          </div>

          <h1 className="home-h1" data-aos="fade-up">
            We Kreate&nbsp;
        
            <TypeAnimation
              className="home-h1-gradient"
              repeat={Infinity}
              style={{transition:'ease-out'}}
              sequence={["Websites", 1000, "Apps", 1000,"Digital Marketing",1000,"Graphic Designing",1000]}
              speed={20}
              deletionSpeed={20}
            /><span className="home-h1-line">|</span>
          </h1>
          <h1 className="mobile-h1">  We Kreate</h1>
          <h1 className="mobile-type-h1"> <TypeAnimation
              className="home-h1-gradient"
              repeat={Infinity}
              style={{transition:'ease-out'}}
              cursor={{ show: true, blink: true, element: '_' }} 
              sequence={["Websites", 1000, "Apps", 1000,"Digital Marketing",1000,"Graphic Designing",1000]}
              speed={20}
              deletionSpeed={20}
            /><span className="home-h1-line">|</span></h1>
        </div>

        <div className="downarrow-container">
        <Plx className="MyAwesomeParallax" parallaxData={parallaxData}>
          <img className="downarrow" src={Doublearrow} />
          </Plx>
        </div>
      </div>
    </>
  );
}

export default Home;
