import React,{useEffect} from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Ourservices from "./components/Ourservices";
import Testimony from "./components/Testimony";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Floatingbtn from "./components/Floatingbtn";
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App">
      <Navbar />
      <Floatingbtn />
      <Home />
      <About />
      <Ourservices />
      <Testimony />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
