import React from "react";
import "./Footer.css";
import logo from "../assets/Footer-kreactive-logo.svg";
import klogo from "../assets/contact-k-logo.svg";
import { Link } from "react-scroll";
import {
  AiOutlineInstagram,
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
function Footer() {
  return (
    <div className="Footer">
   
      <img className="klogo" src={klogo} alt="klogo" />
     
      <div className="footer-container">
        <div className="logo-address-container">
          <div className="Kreative-logo">
          <Link
                activeClass="nothing"
                to="Home"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
            <img className="logo" src={logo} alt="logo" data-aos="fade-up" />
            </Link>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p data-aos="fade-up">
              Streamline your business operations with our cutting-edge software
              solutions
            </p>
          </div>
          <div className="address" data-aos="fade-up">
            <p>
              <a href="mailto:info.kreativepeeps@gmail.com">
                {" "}
                info.kreativepeeps@gmail.com
              </a>
              <br />
              11, NGO B Colony, Min Nagar,
              <br />
              Melagaram, Tenkasi 627818
            </p>
          </div>
        </div>
        <div className="social-nav-container">
          <div className="social" data-aos="fade-up">
            <a href="https://instagram.com/kreativepeeps?igshid=ZDdkNTZiNTM=">
              <AiOutlineInstagram />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100090925675263">
              <AiFillFacebook />
            </a>
            <a href="https://www.linkedin.com/in/kreative-peeps-17375226b">
              <AiFillLinkedin />
            </a>
            <a href="https://twitter.com/KreativePeeps">
              <AiOutlineTwitter />
            </a>
          </div>
          <div className="nav" data-aos="fade-up">
            <a>
              <Link
                activeClass="nothing"
                to="Home"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Home
              </Link>
            </a>
            <a>
              <Link
                activeClass="nothing"
                to="About"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                About Us
              </Link>
            </a>
            <a>
              <Link
                activeClass="nothing"
                to="Ourservices"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Our services
              </Link>
            </a>
            <a>
              <Link
                activeClass="nothing"
                to="Testimony"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                Testimony
              </Link>
            </a>
            <a>
              <Link
                activeClass="nothing"
                to="Contact"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
              >
                Contact Us
              </Link>
            </a>
          </div>
        </div>
      </div>
      <div className="mobile-footer-container">
        <div className="mobile-logo-container">
          {" "}
          <img
            className="mobile-logo"
            src={logo}
            alt="logo"
            data-aos="fade-up"
          />
          <p data-aos="fade-up">
            Streamline your business operations with our cutting-edge software
            solutions
          </p>
        </div>

        <div className="mobile-nav" data-aos="fade-up">
          <a>
            <Link
              activeClass="nothing"
              to="Home"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Home
            </Link>
          </a>
          <a>
            <Link
              activeClass="nothing"
              to="About"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              About Us
            </Link>
          </a>
          <a>
            <Link
              activeClass="nothing"
              to="Ourservices"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Our services
            </Link>
          </a>
          <a>
            <Link
              activeClass="nothing"
              to="Testimony"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Testimony
            </Link>
          </a>
          <a>
            <Link
              activeClass="nothing"
              to="Contact"
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}
            >
              Contact Us
            </Link>
          </a>
        </div>
        <div className="mobile-address">
          <p data-aos="fade-up">
            <a href="mailto:info.kreativepeeps@gmail.com">
              {" "}
              info.kreativepeeps@gmail.com
            </a>
            <br />
            11, NGO B Colony, Min Nagar,
            <br />
            Melagaram, Tenkasi 627818
          </p>
        </div>
        <div className="mobile-social" data-aos="fade-up">
          <a href="https://instagram.com/kreativepeeps?igshid=ZDdkNTZiNTM=">
            <AiOutlineInstagram />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100090925675263">
            <AiFillFacebook />
          </a>
          <a href="https://www.linkedin.com/in/kreative-peeps-17375226b">
            <AiFillLinkedin />
          </a>
          <a href="https://twitter.com/KreativePeeps">
            <AiOutlineTwitter />
          </a>
        </div>
        <p>© 2023 Kreative Peeps</p>
      </div>
      <p className="footer-end">© 2023 Kreative Peeps</p>
    </div>
  );
}

export default Footer;
