import React, { useState } from "react";
import "./navbar.css";
import logo from "../assets/Kreative peeps navlogo.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
function Navbar() {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const [activenavbar, setactivenavbar] = useState(false);
  const changebackground = () => {
    console.log(window.screenY);
    if (window.scrollY >= 10) {
      setactivenavbar(true);
    } else {
      setactivenavbar(false);
    }
  };

  window.addEventListener("scroll", changebackground);
  return (
    <>
      <header className={activenavbar ? "header active" : "header"}>
        
        <Link
              activeClass="nothing"
              to="Home"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
               <img className="navlogo" src={logo} alt="Logo" />
               </Link>
        <nav className={`nav-elements  ${showNavbar && "responsive_nav"}`}>
          <a>
            <Link
              activeClass="nav-active"
              to="Home"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Home
            </Link>
          </a>
          <a>
            <Link
              activeClass="nav-active"
              to="About"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              About Us
            </Link>
          </a>
          <a>
            <Link
              activeClass="nav-active"
              to="Ourservices"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Our services
            </Link>
          </a>
          <a>
            <Link
              activeClass="nav-active"
              to="Testimony"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Testimony
            </Link>
          </a>
          <a>
            <Link
              activeClass="nav-active"
              to="Contact"
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}
            >
              Contact Us
            </Link>
          </a>
     
        </nav>

        <Link
          activeClass="nav-active"
          to="Contact"
          spy={true}
          smooth={true}
          offset={-40}
          duration={500}
        >
          <button className="hire-button"> Contact us </button>
        </Link>
      </header>
    </>
  );
}

export default Navbar;
