import React, { useState, useEffect, useRef } from "react";
import "./ourservices.css";
import Marquee from "react-fast-marquee";
import one from "../assets/one.svg";
import two from "../assets/two.svg";
import three from "../assets/three.svg";
import { Affix } from "antd";
import Plx from "react-plx";
import firstball from "../assets/Frame 57.svg";
const firstData = [
  {
    start: ".ourservice-text-2",
    duration: 200,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const firsttext = [
  {
    start: ".ourservice-text-2",
    duration: 300,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const secondData = [
  {
    start: ".ourservice-text-2 p",
    duration: 200,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: ".ourservice-text-3",
    duration: 200,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const secondtext = [
  {
    start: ".ourservice-text-3",
    duration: 300,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const thirdData = [
  {
    start: ".ourservice-text-3 p",
    duration: 200,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: ".container-4",
    duration: 200,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const thirdtext = [
  {
    start: ".container-4",
    duration: 700,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];

function Ourservices() {
  const [offsetTop, setOffsetTop] = useState(400);
  useEffect(() => {
    const handleResize = () => {
      const screenHeight = window.innerHeight;
      const elementHeight = 100; // replace with the height of your element
      const newOffsetTop = screenHeight / 2 + elementHeight / 2;
      setOffsetTop(newOffsetTop);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="Ourservices" id="Ourservices">
      <div className="ourservices-heading">
        <Marquee gradientWidth="0" speed="50" className="marquee-text">
          <h1>GET THINGS DONE WITH KREATIVE PEEPS &nbsp; &nbsp;</h1>
        </Marquee>
        <div />
        <div className="ourservice-content">
          <div className="ourservice-container container-1">
            <Affix offsetTop={offsetTop}>
              <div className="image-container">
                <Plx className="firstData" parallaxData={firstData}>
                  <img className="image-1" src={one} alt="one-logo" />
                </Plx>
                <Plx className="secondData" parallaxData={secondData}>
                  <img className="image-2" src={two} alt="one-logo" />
                </Plx>
                <Plx className="thirdData" parallaxData={thirdData}>
                  <img className="image-3" src={three} alt="one-logo" />
                </Plx>
              </div>
            </Affix>
            <img className="image-one" src={one} alt="one-logo" />

            <Plx className="MyAwesomeParallax" parallaxData={firsttext}>
              <div className="ourservice-text-1">
          
                  <img
                    className="os-first-ball"
                    alt="ball"
                    src={firstball}
                  />
            
                <h1 data-aos="fade-up">
                  Web
                  <br />
                  Development
                </h1>
                <p data-aos="fade-up">
                  Our team of skilled developers and designers will work with
                  you to create a website that not only looks great, but also
                  functions seamlessly and provides an exceptional user
                  experience. We utilize the latest technologies and best
                  practices to ensure that your website is responsive, scalable,
                  and optimized for performance.
                </p>
              </div>
            </Plx>
          </div>
          <div className="ourservice-container container-2">
            <img className="image-two" src={one} alt="one-logo" />
            <Plx className="MyAwesomeParallax" parallaxData={secondtext}>
              <div className="ourservice-text-2">
      
                  <img
                    className="os-second-ball"
                    alt="ball"
                    src={firstball}        
                  />
             

                <h1 data-aos="fade-up">
                  App
                  <br />
                  Development
                </h1>

                <p data-aos="fade-up">
                  Our team of experienced app developers specializes in creating
                  customized and high-quality apps for a wide range of
                  industries, from healthcare and finance to entertainment and
                  e-commerce. We use the latest technologies and best practices
                  to ensure that your app is not only visually appealing, but
                  also functional, secure, and scalable.
                </p>
              </div>
            </Plx>
          </div>
          <div className="ourservice-container container-3">
            <img className="image-three" src={one} alt="one-logo" />
            <Plx className="MyAwesomeParallax" parallaxData={thirdtext}>
              <div className="ourservice-text-3">
   
                  <img
                    className="os-third-ball"
                    alt="ball"
                    src={firstball}
                
                  />
            
                <h1 data-aos="fade-up">
                  Digital
                  <br />
                  Marketing
                </h1>
                <p data-aos="fade-up">
                  Our team of experienced digital marketers specializes in
                  creating and executing customized strategies that drive
                  results. Whether you're looking to increase website traffic,
                  boost online sales, or improve brand awareness, we can help.
                  We use a data-driven approach to ensure that every campaign is
                  optimized for maximum performance. From search engine
                  optimization (SEO) and pay-per-click (PPC) advertising to
                  social media marketing and email campaigns, we leverage the
                  latest tools and techniques to help you reach your goals.
                </p>
              </div>
            </Plx>
          </div>
          <div className="container-4"></div>
        </div>
        <div className="ourservice-mobile-content">
          <div className="first-mobile-text">
            <img className="os-mobile-first-ball" alt="ball" src={firstball} />
            <h1 data-aos="fade-up">
              Web
              <br />
              Development
            </h1>

            <img
              className="mobile-one"
              src={one}
              alt="one-logo"
              data-aos="fade-up"
            />
            <p data-aos="fade-up">
              Our team of skilled developers and designers will work with you to
              create a website that not only looks great, but also functions
              seamlessly and provides an exceptional user experience. We utilize
              the latest technologies and best practices to ensure that your
              website is responsive, scalable, and optimized for performance.
            </p>
          </div>
          <div className="second-mobile-text">
            <img className="os-mobile-second-ball" alt="ball" src={firstball} />
            <h1 data-aos="fade-up">
              App
              <br />
              Development
            </h1>
            <img
              className="mobile-two"
              src={two}
              alt="two-logo"
              data-aos="fade-up"
            />
            <p data-aos="fade-up">
              Our team of experienced app developers specializes in creating
              customized and high-quality apps for a wide range of industries,
              from healthcare and finance to entertainment and e-commerce. We
              use the latest technologies and best practices to ensure that your
              app is not only visually appealing, but also functional, secure,
              and scalable.
            </p>
          </div>
          <div className="third-mobile-text">
            <img className="os-mobile-third-ball" alt="ball" src={firstball} />
            <h1 data-aos="fade-up">
              Digital
              <br />
              Marketing
            </h1>
            <img
              className="mobile-three"
              src={three}
              alt="three-logo"
              data-aos="fade-up"
            />
            <p data-aos="fade-up">
              Our team of experienced digital marketers specializes in creating
              and executing customized strategies that drive results. Whether
              you're looking to increase website traffic, boost online sales, or
              improve brand awareness, we can help. We use a data-driven
              approach to ensure that every campaign is optimized for maximum
              performance. From search engine optimization (SEO) and
              pay-per-click (PPC) advertising to social media marketing and
              email campaigns, we leverage the latest tools and techniques to
              help you reach your goals.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ourservices;
